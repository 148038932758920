// filings-cache.js

const isDev = window.location.hostname === 'localhost' || 
              window.location.hostname.includes('vercel.app');

const API_URL = isDev 
  ? 'https://optonpo-vercel-optonpo.vercel.app'
  : 'https://optonpo.com';

const searchResultsContainer = document.getElementById('searchResultsContainer');
if (searchResultsContainer) {
  const fetchAndDisplayfilingData = (ein) => {
    fetch(`${API_URL}/ein/filings?ein=${ein}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(filingData => {
        console.log('Fetched filing data:', filingData);
      })
      .catch(error => {
        console.error('Error fetching filing data:', error);
      });
  };

  // Event listener when an organization is selected
  searchResultsContainer.addEventListener('click', (event) => {
    const clickedElement = event.target;
    const ein = clickedElement.getAttribute('data-ein');

    if (ein) {
      // Call fetchAndDisplayfilingData with the selected EIN
      fetchAndDisplayfilingData(ein);
    }
  });
} else {
  // console.error('Error: searchResultsContainer not found');
}
