import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';

document.addEventListener('DOMContentLoaded', () => {
  if (window.location.pathname !== '/about') return;

  const bioGrid = document.querySelector('#bio[data-isotope]');
  if (!bioGrid) return;

  try {
    // Debug log grid items
    const items = bioGrid.querySelectorAll('.bio');
    items.forEach(item => {
      console.log('Grid item classes:', item.className);
    });

    console.log('Initializing bio grid');
    const iso = new Isotope(bioGrid, {
      itemSelector: '.bio',
      layoutMode: 'fitRows',
      percentPosition: true,
      hiddenStyle: { opacity: 0 },
      visibleStyle: { opacity: 1 },
      transitionDuration: '0.4s'
    });

    imagesLoaded(bioGrid, () => {
      console.log('Bio grid images loaded');
      const filterButtons = document.querySelectorAll('#bioTab .nav-link');
      console.log('Filter buttons found:', filterButtons.length);

      filterButtons.forEach(button => {
        button.addEventListener('click', e => {
          e.preventDefault();
          filterButtons.forEach(btn => btn.classList.remove('active'));
          button.classList.add('active');
          const filterValue = button.getAttribute('data-filter');
          
          // Debug logs
          console.log('Button clicked:', button.textContent);
          console.log('Raw filter value:', filterValue);
          
          iso.arrange({ filter: filterValue });
          updateVisibility(filterValue);

          // Log matched items
          const matched = bioGrid.querySelectorAll(filterValue);
          console.log('Matching items:', matched.length);
        });
      });
    });
  } catch (err) {
    console.error('Bio grid error:', err);
  }
});

function updateVisibility(filterValue) {
  console.log('Updating visibility for filter:', filterValue);
  const items = document.querySelectorAll('.bio');
  items.forEach(item => {
    const isVisible = item.matches(filterValue);
    console.log('Item visibility:', item.className, isVisible ? 'visible' : 'hidden');
    item.classList.toggle('is-visible', isVisible);
  });
}