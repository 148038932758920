//optonpo-ui/src/js/client/search.js

import {
  AssetsChart, ExpensesChart, RevenueChart, LiabilitiesChart, RevExpChart, AssLibChart,
  TtlEmplyCntChart, TotalCompChart, AvgCompChart, CompExpChart
} from './chart-cards';

const isDevelopment = process.env.NODE_ENV === 'development';
const API_URL = isDevelopment ? 'https://optonpo-vercel-optonpo.vercel.app' : 'https://optonpo.com';

const fetchConfig = {
  credentials: 'include',
  headers: { 
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
  redirect: 'follow'
};

// Only initialize search functionality if we're on the correct page
document.addEventListener('DOMContentLoaded', () => {
  // Check for required elements before proceeding
  const input = document.getElementById('searchQuery');
  const searchResultsContainer = document.getElementById('searchResultsContainer');
  const logo = document.getElementById('searchLogo');
  const orgCacheDisplay = document.getElementById('orgCacheDisplay');

  // Silently exit if not on search page
  if (!input || !searchResultsContainer || !logo || !orgCacheDisplay) {
    return;
  }

  let clickedInsideInput = false;

  input.addEventListener('focus', () => {
    clickedInsideInput = true;
  });

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const showBlurEffect = () => logo.classList.add('blur-effect');
  const hideBlurEffect = () => logo.classList.remove('blur-effect');
  const hideOrgCacheDisplay = () => orgCacheDisplay.textContent = '';

  const fetchAndDisplayResults = () => {
    showBlurEffect();
    const query = input.value.trim();
    if (!query) {
      searchResultsContainer.innerHTML = '';
      hideBlurEffect();
      hideOrgCacheDisplay();
      return;
    }

    fetch(`${API_URL}/api/search`, {
      ...fetchConfig,
      method: 'POST',
      body: JSON.stringify({ searchQuery: query })
    })
      .then(response => response.json())
      .then(data => {
        searchResultsContainer.innerHTML = '';
        hideBlurEffect();
        hideOrgCacheDisplay();

        if (!Array.isArray(data)) {
          console.error('Invalid data format received:', data);
          searchResultsContainer.textContent = 'Error: Invalid response format';
          return;
        }

        if (data.length === 0) {
          const noResultLink = document.createElement('a');
          noResultLink.textContent = 'No results found';
          const linkContainer = document.createElement('div');
          linkContainer.appendChild(noResultLink);
          searchResultsContainer.appendChild(linkContainer);
          return;
        }

        const topResults = data.slice(0, 20);
        topResults.forEach(result => {
          const orgLink = document.createElement('a');
          orgLink.href = '#orgLink';
          orgLink.style.whiteSpace = 'pre-line';
          orgLink.textContent = `${result.name}\n${result.city}, ${result.state} - ${result.ein}`;
          orgLink.style.borderBottom = '0.25px solid #000';

          orgLink.addEventListener('click', async (event) => {
            event.preventDefault();
            searchResultsContainer.innerHTML = '';
            input.value = result.name;

            try {
              const response = await fetch(`${API_URL}/api/org/${result.ein}`, fetchConfig);
              const orgData = await response.json();

              if (orgData && orgData.filingData) {
                orgCacheDisplay.textContent = JSON.stringify(orgData, null, 2);

                [AssetsChart, RevenueChart, LiabilitiesChart, ExpensesChart,
                  RevExpChart, AssLibChart, TtlEmplyCntChart, TotalCompChart,
                  AvgCompChart, CompExpChart].forEach(chart => {
                    try {
                      chart(orgData);
                    } catch (chartError) {
                      console.error(`Error rendering chart:`, chartError);
                    }
                  });
              } else {
                console.error('Invalid org data structure:', orgData);
                orgCacheDisplay.textContent = 'Invalid organization data structure';
              }
            } catch (error) {
              console.error('API Error:', error);
              orgCacheDisplay.textContent = 'Error fetching organization data';
            }
          });

          const linkContainer = document.createElement('div');
          linkContainer.appendChild(orgLink);
          searchResultsContainer.appendChild(linkContainer);
        });
      })
      .catch(error => {
        console.error('Error:', error);
        hideBlurEffect();
        hideOrgCacheDisplay();
      });
  };

  const debouncedFetch = debounce(fetchAndDisplayResults, 300);
  input.addEventListener('input', debouncedFetch);

  const searchButton = document.getElementById('searchButton');
  if (searchButton) {
    searchButton.addEventListener('click', fetchAndDisplayResults);
  }

  input.addEventListener('keydown', (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const firstResult = searchResultsContainer.querySelector('a');
      if (firstResult) firstResult.click();
    }
  });

  window.addEventListener('click', () => {
    if (clickedInsideInput) {
      fetchAndDisplayResults();
      clickedInsideInput = false;
    }
  });
});