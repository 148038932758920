//optonpo-ui/src/js/client/iso-card-samples.js

//optonpo-ui/src/js/client/iso-card-samples.js

import Isotope from 'isotope-layout';
import 'isotope-packery';
import imagesLoaded from 'imagesloaded';

document.addEventListener('DOMContentLoaded', () => {
  const path = window.location.pathname;
  if (!path.endsWith('/samples') && !path.endsWith('/samples.html')) {
    return;
  }

  const samplesGrid = document.querySelector('.grid[samples-isotope]');
  const filterForm = document.querySelector('#grid-filter');

  if (!samplesGrid) {
    console.error('No grid found with [samples-isotope] attribute');
    return;
  }

  // Add grid sizer if missing
  if (!samplesGrid.querySelector('.grid-sizer')) {
    const sizer = document.createElement('div');
    sizer.className = 'grid-sizer';
    samplesGrid.insertBefore(sizer, samplesGrid.firstChild);
  }

  try {
    const API_URL = 'https://optonpo.com/api/sample-data';
    
    fetch(API_URL)
      .then(res => {
        if (!res.ok) throw new Error('Network response error: ' + res.statusText);
        return res.json();
      })
      .then(samples => {
        console.log('Fetched samples:', samples);

        // Clear grid first
        samplesGrid.innerHTML = '<div class="grid-sizer"></div>';

        // Add grid items
        samples.forEach(sample => {
          const element = document.createElement('div');
          const thumbFile = sample.filename.replace('.pdf', '.png');
          element.className = 'grid-item lift btn-grid-item user-select-disabled';
          element.dataset.keywords = sample.keywords.join(', ');
          element.innerHTML = `
            <div class="card bg-transparent">
              <img class="img-fluid rounded-top-start rounded-bottom-end shadow-lg user-select-disabled" 
                   style="width: 100%; object-fit: cover;" 
                   src="/assets/samples/thumb/${thumbFile}" 
                   alt="${sample.title}"
                   data-aos="fade-up"
                   data-aos-delay="100">
            </div>`;
          samplesGrid.appendChild(element);
        });

        // Initialize grid after images load
        imagesLoaded(samplesGrid, function() {
          const iso = new Isotope(samplesGrid, {
            itemSelector: '.grid-item',
            layoutMode: 'packery',
            packery: {
              gutter: 20,
              columnWidth: '.grid-sizer',
              horizontalOrder: true
            },
            percentPosition: true,
            transitionDuration: '0.4s'
          });

          // Force initial layout
          iso.layout();

          // Handle window resize
          let resizeTimeout;
          window.addEventListener('resize', () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
              iso.layout();
            }, 100);
          });

          // Handle scroll events
          samplesGrid.addEventListener('scroll', () => {
            iso.layout();
          });

          // Handle filtering
          filterForm?.addEventListener('submit', (e) => {
            e.preventDefault();
            const searchTerm = e.target.querySelector('#filterInput')?.value.toLowerCase().trim() || '';

            iso.arrange({
              filter: (itemElem) => {
                const keywords = itemElem.dataset.keywords?.toLowerCase() || '';
                return searchTerm === '' || keywords.includes(searchTerm);
              }
            });
          });

          // Force layout update on page load
          window.addEventListener('load', () => {
            iso.layout();
          });
        });
      })
      .catch(err => {
        console.error('Sample data fetch error:', err);
        samplesGrid.innerHTML = '<p>Error loading samples. Please try again later.</p>';
      });

  } catch (err) {
    console.error('Samples grid error:', err);
  }
});