//optonpo-ui/src/js/client/iso-card-charts.js

import Isotope from 'isotope-layout';

document.addEventListener('DOMContentLoaded', () => {
  const optoGrid = document.querySelector('.grid[charts-isotope]');
  if (!optoGrid) return;

  try {
    // Debug log grid items
    const items = optoGrid.querySelectorAll('.chart-item');
    items.forEach(item => {
      console.log('Grid item classes:', item.className);
    });

    // Initialize Isotope
    const iso = new Isotope(optoGrid, {
      itemSelector: '.chart-item',
      layoutMode: 'fitRows'
    });

    // Get filter buttons
    const filterBtns = document.querySelectorAll('#grid-filter .nav-link');
    // Add click handlers
    filterBtns.forEach(btn => {
      btn.addEventListener('click', () => {
        // Remove active from all buttons
        filterBtns.forEach(b => b.classList.remove('active'));
        // Add active to clicked button
        btn.classList.add('active');
        // Get category
        let filterValue = btn.getAttribute('data-category');
        // Debug logs
        console.log('Button clicked:', btn.textContent);
        console.log('Raw filter value:', filterValue);
        // Apply filter
        if (filterValue === null || filterValue === '') {
          iso.arrange({ filter: '*' });
        } else {
          filterValue = filterValue.replace(/^\./, '');
          console.log('Applied filter:', `.${filterValue}`);
          iso.arrange({ filter: `.${filterValue}` });
        }
        // Log matched items
        const matched = optoGrid.querySelectorAll(`.${filterValue}`);
        console.log('Matching items:', matched.length);
      });
    });

  } catch (err) {
    console.error('Optometry grid error:', err);
  }
});