//optonpo-ui/src/js/client/loading.js

function createLoadingPlaceholder() {
    const placeholder = document.createElement('div');
    placeholder.className = 'loading-placeholder d-flex align-items-center justify-content-center';
    
    const logo = document.createElement('img');
    logo.src = '/assets/img/Opto_Logo1_Warn.svg';
    logo.className = 'animated-logo';
    
    placeholder.appendChild(logo);
    return placeholder;
  }
  
  // Update existing code
  const detailContent = document.createElement('div');
  detailContent.className = 'w-100 h-100 d-flex align-items-center justify-content-center';
  detailContent.style.overflow = 'scroll';
  detailContent.appendChild(createLoadingPlaceholder());