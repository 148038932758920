//optonpo-ui/src/js/client/samples.js

import Flickity from "flickity";
import imagesLoaded from "imagesloaded";

const pdfCache = new Map();
const pdfOptions = {
  cMapUrl: 'https://unpkg.com/pdfjs-dist@latest/cmaps/',
  cMapPacked: true
};

const createLoadingPlaceholder = () => {
  const placeholder = document.createElement('div');
  placeholder.className = 'loading-placeholder d-flex align-items-center justify-content-center';

  const logo = document.createElement('img');
  logo.src = '/assets/img/Opto_Logo1_Warn.svg';
  logo.className = 'animated-logo';
  logo.alt = 'Opto Loading';

  placeholder.appendChild(logo);
  return placeholder;
};

const handleScroll = () => {
  const viewer = document.getElementById('viewer');
  if (document.body.getBoundingClientRect().top >= 0) {
    viewer.style.position = 'relative';
  }
};

const scrollToFilter = () => {
  const gridFilter = document.getElementById('grid-filter');
  if (gridFilter) {
    const filterTop = gridFilter.getBoundingClientRect().top;
    const offset = 100;
    window.scrollTo({
      top: window.scrollY + filterTop - offset,
      behavior: 'smooth'
    });
  }
};

const handleSubmit = (e) => {
  e.preventDefault();
  scrollToFilter();
};

const handleGridClick = (e) => {
  const gridItem = e.target.closest('.grid-item');
  if (!gridItem) return;

  scrollToFilter();
  const viewer = document.getElementById('viewer');
  viewer.style.position = 'sticky';
  viewer.style.bottom = '2rem';

  updateViewer(gridItem);
};

const updateViewer = async (gridItem) => {
  const thumbnail = gridItem.querySelector('img');
  const filename = thumbnail.src.split('/').pop();

  try {
    const API_URL = 'https://optonpo.com';
    if (!gridItem.dataset.metadata) {
      const response = await fetch(`${API_URL}/api/sample-data/${filename}`);
      const metadata = await response.json();
      gridItem.dataset.keywords = metadata.keywords.join(', ');
      gridItem.dataset.metadata = JSON.stringify(metadata);
    }

    console.log('Clearing existing viewers...');
    let flkty = null;
    let detailFlkty = null;
    const viewerElements = {
      title: document.querySelector('#viewer .text-uppercase.text-dark'),
      container: document.getElementById('sampleViewer'),
      sampleSlider: document.getElementById('sampleSlider'),
      detailSlider: document.getElementById('detailSlider')
    };

    if (flkty) flkty.destroy();
    if (detailFlkty) detailFlkty.destroy();
    flkty = null;
    detailFlkty = null;

    viewerElements.sampleSlider.innerHTML = '';
    viewerElements.detailSlider.innerHTML = '';

    const thumbnailPlaceholder = document.createElement('div');
    thumbnailPlaceholder.className = 'sample-slider w-100 h-100';
    thumbnailPlaceholder.innerHTML = `
      <div class="card bg-transparent w-100 h-100">
        <img src="${thumbnail.src}" 
             alt="Loading..." 
             class="img-fluid rounded-top-start rounded-bottom-end bg-transparent"
             style="object-fit: contain; width: 100%; height: 100%;">
      </div>
    `;
    viewerElements.sampleSlider.appendChild(thumbnailPlaceholder);

    const metadata = JSON.parse(gridItem.dataset.metadata);
    const detailSlider = document.createElement('div');
    detailSlider.className = 'detail-slider w-100 h-100';
    const detailContent = document.createElement('div');
    detailContent.className = 'w-100 h-100 d-flex align-items-center justify-content-center';
    detailContent.style.overflow = 'scroll';
    detailContent.innerHTML = `
      <div class="col-12 px-4 text-left">
        <h6 class="text-uppercase text-secondary mb-5">${metadata.title || 'Untitled'}</h6>
        <blockquote class="mb-3">
          <p class="text-sm text-dark" style="text-align: justify;">${metadata.subject || 'No description available'}</p>
          <p class="h6 text-uppercase text-secondary mb-0">
            Tags: ${Array.isArray(metadata.keywords) ? metadata.keywords.map(kw =>
        `<span class="keyword-tag lift" style="cursor: pointer;">${kw}</span>`
      ).join(', ') : 'None'}
          </p>
        </blockquote>
      </div>
    `;

    detailSlider.appendChild(detailContent);
    viewerElements.detailSlider.appendChild(detailSlider);

    detailContent.querySelectorAll('.keyword-tag').forEach(tag => {
      tag.addEventListener('click', () => {
        const filterInput = document.getElementById('filterInput');
        filterInput.value = tag.textContent;
        const gridFilter = document.getElementById('grid-filter');
        gridFilter.dispatchEvent(new Event('submit'));
      });
    });

    const pdfPath = thumbnail.src.replace('/thumb/', '/').replace(/\.(jpg|png)$/, '.pdf');
    let pdf = pdfCache.get(pdfPath) || await pdfjsLib.getDocument({ ...pdfOptions, url: pdfPath }).promise;
    if (!pdfCache.has(pdfPath)) pdfCache.set(pdfPath, pdf);

    const pages = await Promise.all(
      Array.from({ length: pdf.numPages }, (_, i) => pdf.getPage(i + 1))
    );

    thumbnailPlaceholder.style.opacity = '0';
    thumbnailPlaceholder.style.transition = 'opacity 0.3s ease-out';
    setTimeout(() => thumbnailPlaceholder.remove(), 300);

    await Promise.all(pages.map(async page => {
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      await page.render({
        canvasContext: ctx,
        viewport: viewport
      }).promise;

      const sampleSlider = document.createElement('div');
      sampleSlider.className = 'sample-slider w-100 h-100';

      const sampleContent = document.createElement('div');
      sampleContent.className = 'w-100 h-100 d-flex align-items-center justify-content-center user-select-disabled';
      sampleContent.style.overflow = 'scroll';
      sampleContent.style.backgroundImage = `url(${canvas.toDataURL()})`;
      sampleContent.style.backgroundSize = 'contain';
      sampleContent.style.backgroundRepeat = 'no-repeat';
      sampleContent.style.backgroundPosition = 'center';
      sampleContent.style.userSelect = 'none';
      sampleContent.style.pointerEvents = 'none';
      sampleContent.addEventListener('contextmenu', e => e.preventDefault());
      sampleContent.addEventListener('dragstart', e => e.preventDefault());

      sampleSlider.appendChild(sampleContent);
      viewerElements.sampleSlider.appendChild(sampleSlider);
    }));

    viewerElements.sampleSlider.offsetHeight;
    viewerElements.detailSlider.offsetHeight;

    flkty = new Flickity(viewerElements.sampleSlider, {
      hash: true,
      fade: true,
      imagesLoaded: true,
      pageDots: false,
      prevNextButtons: true,
      asNavFor: '#detailSlider',
      draggable: true,
      cellAlign: 'center',
      contain: true,
      adaptiveHeight: false,
      wrapAround: false
    });

    detailFlkty = new Flickity(viewerElements.detailSlider, {
      fade: true,
      draggable: false,
      prevNextButtons: false,
      pageDots: false,
      adaptiveHeight: false
    });

  } catch (error) {
    console.error('Viewer update error:', error);
  }
};

document.addEventListener('DOMContentLoaded', async function () {
  const path = window.location.pathname;

  // Check if the current page is /samples.html
  if (typeof path !== 'string' || (!path.endsWith('/samples') && !path.endsWith('/samples.html'))) {
    return;
  }

  const gridFilter = document.getElementById('grid-filter');
  if (!gridFilter) {
    console.error('Grid filter element not found');
    return;
  }

  const grid = document.querySelector('[samples-isotope]');
  if (!grid) {
    console.error('Grid element not found');
    return;
  }

  const thumbDir = '/assets/samples/thumb';

  const API_URL = 'https://optonpo.com';

  const filterInput = document.getElementById('filterInput');
  if (!filterInput) {
    console.error('Filter input element not found');
    return;
  }

  try {
    const response = await fetch(`${API_URL}/api/sample-data`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const samples = await response.json();

    // Preload metadata for all samples
    const loadAllMetadata = async (samples) => {
      filterInput.disabled = true;
      filterInput.placeholder = 'Loading metadata...';
    
      const gridItems = document.querySelectorAll('.grid-item');
      const metadataPromises = Array.from(gridItems).map(async (item) => {
        const thumbnail = item.querySelector('img');
        const filename = thumbnail.src.split('/').pop();

        try {
          const response = await fetch(`${API_URL}/api/sample-data/${filename}`);
          const metadata = await response.json();
          item.dataset.keywords = metadata.keywords.join(', ');
          item.dataset.metadata = JSON.stringify(metadata);
        } catch (error) {
          console.error(`Metadata fetch failed for ${filename}:`, error);
        }
      });

      await Promise.all(metadataPromises);
      filterInput.disabled = false;
      filterInput.placeholder = 'Search samples...';
    };

    await loadAllMetadata(samples);

    // Security controls
    document.addEventListener('keydown', function (e) {
      if (e.key === 'p' && (e.ctrlKey || e.metaKey)) e.preventDefault();
      if (e.key === 'u' && (e.ctrlKey || e.metaKey)) e.preventDefault();
      if (e.key === 's' && (e.ctrlKey || e.metaKey)) e.preventDefault();
    });

    document.addEventListener('contextmenu', e => e.preventDefault());
    document.addEventListener('dragstart', e => e.preventDefault());

    // Viewer setup
    const viewer = document.getElementById('viewer');
    let flkty = null;
    let detailFlkty = null;

    const viewerElements = {
      title: document.querySelector('#viewer .text-uppercase.text-dark'),
      container: document.getElementById('sampleViewer'),
      sampleSlider: document.getElementById('sampleSlider'),
      detailSlider: document.getElementById('detailSlider')
    };

    // Event listeners
    window.addEventListener('scroll', handleScroll);
    filterInput.addEventListener('focus', scrollToFilter);
    gridFilter.addEventListener('submit', handleSubmit);
    grid.addEventListener('click', handleGridClick);

  } catch (error) {
    console.error('Error loading thumbnail directory:', error);
  } finally {
    const cleanup = () => {
      window.removeEventListener('scroll', handleScroll);
      filterInput?.removeEventListener('focus', scrollToFilter);
      gridFilter?.removeEventListener('submit', handleSubmit);
      grid?.removeEventListener('click', handleGridClick);
    };

    // Attach cleanup to window unload
    window.addEventListener('unload', cleanup);
    return cleanup;
  }
});